import React, { PropsWithChildren, useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

import 'slick-carousel/slick/slick.css';

type CarouselProps = {
	className?: string;
};

const BREAKPOINT_REMOVE_PADDING = 772;
const CENTER_PADDING_WIDTH = '30px';

export const Carousel = ({ children, className }: PropsWithChildren<CarouselProps>) => {
	const [centerPadding, setCenterPadding] = useState(CENTER_PADDING_WIDTH);

	useEffect(() => {
		function determineCenterPadding() {
			if (window.innerWidth < BREAKPOINT_REMOVE_PADDING) {
				setCenterPadding('0px');
			} else setCenterPadding(CENTER_PADDING_WIDTH);
		}

		determineCenterPadding(); // Run on instantiation
		window.addEventListener('resize', determineCenterPadding);
		return () => window.removeEventListener('resize', determineCenterPadding);
	}, []);

	const settings: Settings = {
		dots: false,
		infinite: true,
		autoplay: false,
		centerMode: true,
		autoplaySpeed: 8000,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <NextButton />,
		prevArrow: <></>,
		swipeToSlide: true,
		adaptiveHeight: true,
		centerPadding,
	};

	return (
		<div className={className}>
			<Slider {...settings}>{children}</Slider>
		</div>
	);
};

const NextButton = ({ onClick }: { onClick?: () => void }) => (
	<span
		className={`
    absolute
    right-4 md:right-20 md-lg:right-20 lg:right-28
    top-32 md:top-52 lg:top-72
  `}
	>
		<Button onClick={onClick} type="filled" size="large" iconOnly={NextIcon} />
	</span>
);
