import { NewsThumbnail, NewsThumbnailProps } from './NewsThumbnail';

export type NewsThumbnailListProps = {
	className?: string;
	newsThumbnails: Omit<NewsThumbnailProps, 'className'>[];
};

export const NewsThumbnailList = ({ className, newsThumbnails }: NewsThumbnailListProps) => {
	return (
		<div className={className}>
			<ul>
				{newsThumbnails.map((newsThumbnail, index) => (
					<li key={index} className="mb-20 lg:mb-20">
						<NewsThumbnail {...newsThumbnail} />
					</li>
				))}
			</ul>
		</div>
	);
};
