import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { LayoutGrid } from '@apps/web/components/molecules/layout-grid/LayoutGrid';
import { Section } from '@apps/web/components/molecules/section/Section';
import { InternalOrExternalLink } from '@apps/web/lib/sanity/types';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

type UspProps = {
	title: string;
	description: string;
	link?: InternalOrExternalLink;
};
export const Usp = ({ title, description, link }: UspProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [offsetTop, setOffsetTop] = useState(0);
	const { scrollY } = useViewportScroll();
	const { locale } = useRouter();

	useEffect(() => {
		const onResize = () => {
			setOffsetTop(ref?.current?.offsetHeight || 0);
		};

		onResize();
		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
	}, [ref]);

	const y = useTransform(scrollY, [(offsetTop / 1.5) * -1, offsetTop / 1.5], ['100%', '0%']);

	return (
		<Section paddingVerticalSize="small" className="bg-sand-tint-50">
			<div className="flex flex-col layout-padding-negation md-lg:flex-row">
				<LayoutGrid className="relative mr-8 md:mr-0 md-lg:block md-lg:w-1/2">
					<motion.div
						ref={ref}
						className="col-span-10 col-start-1 py-24 bg-brand-cream-yellow"
						style={{ y }}
					>
						<h2
							className={`${
								locale === 'de' ? 'front-page-custom-hero-text--de' : 'front-page-custom-hero-text--en'
							} max-w-xxs md:max-w-sm lg:max-w-md mx-auto text-brand-blue hyphens-manual`}
						>
							{title}
						</h2>
					</motion.div>
				</LayoutGrid>
				<LayoutGrid className="ml-8 -mt-20 md:ml-0 md-lg:block md-lg:w-1/2 md-lg:mt-28">
					<div className="col-span-4 col-start-1 pt-48 pl-10 pr-8 bg-white md:col-start-3 md:col-span-10 md:px-20 pb-28 md:py-40 lg:py-40">
						<p className="max-w-lg body-large text-brand-blue">{description}</p>
						{link && (
							<Button
								className="mt-8"
								size="medium"
								color="action-red"
								type="filled"
								href={link.internalLink || link.externalLink}
								iconRight={NextIcon}
							>
								{link.text}
							</Button>
						)}
					</div>
				</LayoutGrid>
			</div>
		</Section>
	);
};
