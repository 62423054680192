import { ProcessItem, ProcessItemType } from './ProcessItem';

export type ProcessItemListProps = {
	className?: string;
	processItems: Omit<ProcessItemType, 'color' | 'step'>[];
	color: string;
};

export const ProcessItemList = ({ className, processItems, color }: ProcessItemListProps) => {
	return (
		<ul className={`space-y-8 ${className}`}>
			{processItems.map((processItem, i) => (
				<li key={processItem.title}>
					<ProcessItem
						title={processItem.title}
						description={processItem.description}
						step={i + 1}
						color={color}
					/>
				</li>
			))}
		</ul>
	);
};
