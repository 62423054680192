export const StripTags = (input: string, allowed: string | string[]) => {
	if (!input || !allowed) {
		return;
	}

	allowed = (`${allowed || ''}`.toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
	const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
	const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

	return input.replace(commentsAndPhpTags, '').replace(tags, ($0: string, $1: string) => {
		return allowed.indexOf(`<${$1.toLowerCase()}>`) > -1 ? $0 : '';
	});
};

// Function reference: https://stackoverflow.com/questions/31259295/javascript-allow-only-specific-html-tags/31259386#31259386
