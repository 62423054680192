import Img from 'next/image';

import { AltTextImageType, InternalOrExternalLink } from '@apps/web/lib/sanity/types';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

import { RichText } from '../rich-text/RichText';

export type ColumnProps = {
	title: string;
	description: string;
	image: AltTextImageType;
	link?: InternalOrExternalLink;
	bgColor: string;
};

export const Column = ({ title, description, image, link, bgColor }: ColumnProps) => {
	return (
		<div className="flex flex-col gap-8 items-start">
			<div className={'relative w-full pb-[61%] lg:pb-[72%]'}>
				<Img
					alt={image?.altText}
					src={image?.asset?.url}
					className={'object-cover w-full md:max-w-5xl'}
					layout="fill"
					objectFit="cover"
					placeholder="blur"
					blurDataURL={image?.asset?.lqip}
				/>
			</div>

			<h3>{title}</h3>
			<RichText blocks={description} />
			{link ? (
				<Button
					iconRight={NextIcon}
					href={link?.internalLink || link?.externalLink}
					type="text"
					size="medium"
					color={bgColor === '#17335B' ? 'white' : 'action-red'}
				>
					{link?.text}
				</Button>
			) : null}
		</div>
	);
};

export default Column;
