// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BlockContent from '@sanity/block-content-to-react';
import Img from 'next/image';
import slugify from 'slugify';

import { RichTextType } from '@apps/web/lib/sanity/types';
import { StripTags } from '@apps/web/utils/stripTags';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';
import { Link } from '@canify/ui-link';

import s from './RichText.module.scss';

type RichTextProps = {
	className?: string;
	blocks: RichTextType;
};

export const RichText = ({ blocks, className }: RichTextProps) => {
	return (
		<BlockContent serializers={serializers} className={`${s['rich-text']} ${className}`} blocks={blocks} />
	);
};

const serializers = {
	types: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		block: (props: any) => {
			const { style = 'normal' } = props.node;

			if (style === 'h2') {
				return <h2 className="h3">{props.children}</h2>;
			}

			if (style === 'h3') {
				return <h3 className="h5">{props.children}</h3>;
			}

			if (style === 'blockquote') {
				return (
					<blockquote className="bg-blue-tint-25 text-brand-blue text-[18px] py-10 px-14 md:pr-10 md:pl-28  my-20 -ml-8 md:-ml-16 lg:-ml-32">
						<div className="relative">{props.children}</div>
					</blockquote>
				);
			}

			return BlockContent.defaultSerializers.types.block(props);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		captionImage: (props: any) => {
			const { asset, altText, caption } = props.node;

			if (!asset) {
				// eslint-disable-next-line no-console
				console.log('No image asset for: ', props.node);
				return null;
			}

			const strippedCaption = StripTags(caption, '<sup><sub>');

			return (
				<div className="my-10 md:my-12 -ml-8 md:-ml-16 lg:-ml-32">
					<Img
						width={asset.width}
						height={asset.height}
						layout="responsive"
						alt={altText}
						src={asset.url || '/'} // '/' is fallback image url in case none were provided in sanity (convenient for the editor while mocking images)
					/>
					{strippedCaption && (
						<p
							dangerouslySetInnerHTML={{ __html: strippedCaption }}
							className="mt-4 ml-8 lg:ml-0 text-sand-shade-50"
						/>
					)}
				</div>
			);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		downloadLink: (props: any) => {
			const { asset, linkText, text } = props.node;

			const fileName = asset?.extension
				? `${slugify(text, {
						lower: true,
				  })}.${asset?.extension}`
				: `${slugify(text, {
						lower: true,
				  })}`;

			return (
				<div className="bg-blue-tint-25 text-brand-blue text-[18px] py-8 px-5 my-4">
					<div className="relative flex flex-wrap items-center justify-between">
						<h5 className="relative flex-none mr-10 text-brand-blue">{text}</h5>
						<Button
							iconRight={NextIcon}
							size="large"
							type="text"
							className="mr-8"
							href={`${asset.url}?dl=${fileName}`}
							download={fileName}
						>
							{linkText}
						</Button>
					</div>
				</div>
			);
		},
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	listItem: (l: any) => {
		if (l.node.listItem === 'bullet') {
			return (
				<li className="ml-10 list-disc text-brand-ochre-red list-outsite">
					<span className="text-brand-blue">{l.children}</span>
				</li>
			);
		}

		return (
			<li className="relative ml-10">
				<span className="absolute -left-5 font-display text-brand-ochre-red">{l.index + 1}</span>
				<span className="text-brand-blue">{l.children}</span>
			</li>
		);
	},
	marks: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		sup: (props: any) => <sup>{props.children}</sup>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		sub: (props: any) => <sub>{props.children}</sub>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		internalLink: (props: any) => {
			const { slug } = props.mark;
			if (!slug) {
				return null;
			}

			const href = `${slug.current}`;

			return <Link to={href}>{props.children}</Link>;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		link: (props: any) => {
			const { href } = props.mark;
			if (!href) {
				// eslint-disable-next-line no-console
				console.log('No href for: ', props.node);

				return null;
			}

			return (
				<a href={href} target="_blank" rel="noreferrer" className='underline'>
					{props.children}
				</a>
			);
		},
	},
};
