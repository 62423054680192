import { Carousel } from '@apps/web/components/molecules/carousel/Carousel';
import { Section } from '@apps/web/components/molecules/section/Section';
import {
	DoctorInfoBox,
	DoctorInfoBoxProps,
} from '@apps/web/components/organisms/doctor-info-box/DoctorInfoBox';
import { InternalOrExternalLink } from '@apps/web/lib/sanity/types';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

export type FrontpageDoctorsProps = {
	title?: string;
	doctors: Omit<DoctorInfoBoxProps, 'className'>[];
	link?: InternalOrExternalLink;
};

const Doctors = ({ title, doctors, link }: FrontpageDoctorsProps): JSX.Element => {
	return (
		<>
			<Section paddingVerticalSize="small">
				<div className="relative">
					<h2
						className={`
						mb-12 md:mb-20 md-lg:mb-10
						top-0 lg:top-24
						relative lg:absolute
						text-brand-blue z-10 max-w-lg
					`}
					>
						{title}
					</h2>

					<Carousel
						className={`
							mb-12
							-ml-7 -mr-7  md:ml-auto lg:-mr-12
							max-w-4xl md-lg:max-w-6xl
						`}
					>
						{doctors.map((doctor, i) => (
							<DoctorInfoBox key={i} {...doctor} />
						))}
					</Carousel>
					{link && (
						<span className="relative lg:absolute bottom-0">
							<Button
								iconRight={NextIcon}
								href={link.internalLink || link.externalLink}
								type="outlined"
								size="medium"
								color="action-blue"
							>
								{link.text}
							</Button>
						</span>
					)}
				</div>
			</Section>
		</>
	);
};

export default Doctors;
