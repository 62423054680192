import * as React from 'react';
import { PropsWithChildren } from 'react';

type LayoutGridProps = {
	className?: string;
};

export const LayoutGrid = ({ children, className }: PropsWithChildren<LayoutGridProps>) => {
	return <div className={`grid-layout ${className}`}>{children}</div>;
};
