import Img from 'next/image';

import { AltTextImageType, InternalOrExternalLink } from '@apps/web/lib/sanity/types';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

export type ImageAndTextProps = {
	className?: string;
	title: string;
	text: string;
	image: AltTextImageType;
	imagePosition: 'left' | 'right';
	link?: InternalOrExternalLink;
};

export const ImageAndText = ({ className, title, text, link, image, imagePosition }: ImageAndTextProps) => {
	return (
		<div
			className={`relative flex ${
				imagePosition === 'right' ? 'flex-row' : 'flex-row-reverse'
			} items-start ${className}`}
		>
			<div className="bg-white max-w-xl md:max-w-none w-full p-8 md:p-20 md:w-9/12">
				<div>
					<h3 className="text-brand-blue mb-8 md:mb-6">{title}</h3>
					{image?.asset && (
						<div
							className={`md:hidden max-w-sm mb-8 ${
								imagePosition === 'right' ? 'ml-auto -mr-8' : 'mr-auto -ml-8'
							}`}
						>
							<Img
								src={image.asset.url}
								alt={image.altText}
								width={416}
								height={416}
								layout="responsive"
								objectFit="cover"
								placeholder="blur"
								blurDataURL={image.asset.lqip}
							/>
						</div>
					)}
					<p className="mb-2">{text}</p>
					{link && (
						<Button
							type="text"
							size="large"
							href={link.internalLink || link.externalLink}
							iconRight={NextIcon}
						>
							{link.text}
						</Button>
					)}
				</div>
			</div>
			{image?.asset && (
				<div
					className={`hidden md:grid mt-10 w-1/2 lg:w-2/6 max-w-md ${
						imagePosition === 'right' ? '-ml-14' : '-mr-14'
					}`}
				>
					<Img
						src={image.asset.url}
						alt={image.altText}
						width={416}
						height={416}
						layout="responsive"
						objectFit="cover"
						placeholder="blur"
						blurDataURL={image.asset.lqip}
					/>
				</div>
			)}
		</div>
	);
};
