import { useEffect, useRef, useState } from 'react';

import { Button } from '@canify/ui-button';
import { PlayIcon } from '@canify/ui-icon';

type VideoProps = {
	src: string;
	isBackgroundVideo?: boolean;
};

// TODO: Handle out of view? Handle error on autoplay?

export const Video = ({ src, isBackgroundVideo }: VideoProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isPlaying, setIsPlaying] = useState(isBackgroundVideo);
	useEffect(() => {
		if (isPlaying) {
			// eslint-disable-next-line no-console
			videoRef.current?.play().catch((err) => console.error(err));
		}
	}, [isPlaying]);

	const showButton = !isPlaying && !isBackgroundVideo;
	const showControls = isPlaying && !isBackgroundVideo;
	const isLooping = !!isBackgroundVideo;
	const isMuted = !!isBackgroundVideo;

	return (
		<div className={`${isBackgroundVideo ? 'w-full h-full absolute top-0 left-0' : 'relative'}`}>
			<video
				className={`${isBackgroundVideo ? 'w-full h-full object-cover' : ''}`}
				controls={showControls}
				loop={isLooping}
				playsInline={true}
				muted={isMuted}
				ref={videoRef}
				preload="metadata"
			>
				<source src={[src, 't=0.1'].join('#')} type="video/mp4" />
			</video>
			{showButton && (
				<span className="absolute md:scale-100 scale-75 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					<Button size="large" type="filled" iconOnly={PlayIcon} onClick={() => setIsPlaying(true)} />
				</span>
			)}
		</div>
	);
};
