import { Trans } from '@lingui/macro';
import Img from 'next/image';

import { AltTextImageType } from '@apps/web/lib/sanity/types';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

export type NewsThumbnailProps = {
	className?: string;
	image?: AltTextImageType;
	title: string;
	summary: string;
	slug: string;
};

export const NewsThumbnail = ({ className, image, title, summary, slug }: NewsThumbnailProps) => {
	return (
		<div className={`flex ${className}`}>
			{image?.asset && (
				<div className="w-80 hidden lg:grid flex-shrink-0 mr-20">
					<Img
						src={image.asset.url}
						alt={image.altText}
						width={323}
						height={213}
						layout="responsive"
						objectFit="cover"
						placeholder="blur"
						blurDataURL={image.asset.lqip}
					/>
				</div>
			)}
			<div className="flex-grow max-w-4xl">
				<h3 className="text-brand-blue mb-7 max-w-lg lg:max-w-none">{title}</h3>
				<div className="flex flex-col md:flex-row">
					{image?.asset && (
						<div className="lg:hidden w-full md:w-72 mb-7 mr-16 flex-shrink-0">
							<Img
								src={image.asset.url}
								alt={image.altText}
								width={323}
								height={213}
								layout="responsive"
								objectFit="cover"
								placeholder="blur"
								blurDataURL={image.asset.lqip}
							/>
						</div>
					)}
					<div>
						<p className="text-brand-blue mb-3 md:mb-3">{summary}</p>
						<Button href={slug} size="medium" type="text" iconRight={NextIcon}>
							<Trans>Read</Trans>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
