import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { Divider } from '@apps/web/components/atoms/divider/Divider';
import { RichText } from '@apps/web/components/molecules/rich-text/RichText';
import { DEFAULT_EASE } from '@apps/web/constants';
import { RichTextType } from '@apps/web/lib/sanity/types';
import { ChevronDownIcon } from '@canify/ui-icon';

import { LayoutGrid } from '../layout-grid/LayoutGrid';
import { Section } from '../section/Section';

type AccordionProps = {
	className?: string;
	title?: string;
	description?: RichTextType;
	items: { accordionItemTitle: string; accordionItemDescription?: RichTextType }[];
	bgColor: {
		title: string;
		value: string;
  };
	referencesTitle?: string;
	references?: string;
	defaultClosed?: boolean;
};

export const Accordion = ({
	items,
	title,
	description,
	bgColor,
	referencesTitle,
	references,
	className,
	defaultClosed,
}: AccordionProps) => {
	const [expanded, setExpanded] = useState<false | number>(-1);

	useEffect(() => {
		const initialIndex = defaultClosed ? -1 : 0;
		setExpanded(initialIndex);
	}, [defaultClosed]);

	return (
		<Section paddingVerticalSize="small" className={`py-20 ${
				bgColor.value === '#FFFFFF'
					? 'bg-white text-brand-blue'
					: 'bg-brand-blue text-white'
			}`}>
			<LayoutGrid
			>
				{title ? (
					<h2 className={'mb-8 col-span-12'}>
						{title}
					</h2>
				) : null}
				{description ? (
					<LayoutGrid className="col-span-12">
						<div className="max-w-3xl col-span-11 col-start-1 lg:col-span-6">
							<div
								className={'mb-20 rich-text-large lg:mb-24'}
							>
								<RichText blocks={description} />
							</div>
						</div>
					</LayoutGrid>
				) : null}

				<div className={`${className}`}>
					{items?.map((item, i) => {
						const isOpen = i === expanded;

						return (
							<div key={item.accordionItemTitle}>
								<motion.div
									className="pb-8 relative hover:cursor-pointer"
									initial={false}
									onClick={() => setExpanded(isOpen ? false : i)}
								>
									<p
										className={'h5 pr-8 md:pr-16'}
									>
										{item.accordionItemTitle}
									</p>
									<motion.span
										animate={isOpen ? 'open' : 'closed'}
										variants={{
											open: { rotate: '180deg' },
											closed: { rotate: '0deg' },
										}}
										transition={{ duration: 0.2, ease: DEFAULT_EASE }}
										className="absolute -top-1 right-0"
									>
										<ChevronDownIcon
											className={`${bgColor.value === '#FFFFFF' ? 'text-brand-ochre-red' : 'text-white'}`}
										/>
									</motion.span>
								</motion.div>
								<AnimatePresence initial={false}>
									{isOpen && (
										<motion.section
											className="overflow-hidden"
											key="content"
											initial="collapsed"
											animate="open"
											exit="collapsed"
											variants={{
												open: { opacity: 1, height: 'auto' },
												collapsed: { opacity: 0, height: 0 },
											}}
											transition={{ duration: 0.5, ease: DEFAULT_EASE }}
										>
											<div
												className={'pb-8 md:pr-16'}
											>
												<RichText blocks={item.accordionItemDescription} />
											</div>
										</motion.section>
									)}
								</AnimatePresence>
								<Divider className="mb-8" isLight={bgColor.value} />
							</div>
						);
					})}
				</div>
				{references ? (
					<LayoutGrid className="md:max-w-3xl col-span-12 col-start-1 lg:col-start-2">
						<div className="max-w-3xl col-span-11 col-start-1">
							{referencesTitle ? (
								<h4
									className={'pr-8 mb-8 font-black h5'}
								>
									{referencesTitle}
								</h4>
							) : null}
							<div
								className={'mb-20 rich-text-body lg:mb-24'}
							>
								<RichText blocks={references} />
							</div>
						</div>
					</LayoutGrid>
				) : null}
			</LayoutGrid>
		</Section>
	);
};
