import { LayoutGrid } from '@apps/web/components/molecules/layout-grid/LayoutGrid';
import {
	ProcessItemList,
	ProcessItemListProps,
} from '@apps/web/components/molecules/process-item/ProcessItemList';
import { Section } from '@apps/web/components/molecules/section/Section';
import { InternalOrExternalLink } from '@apps/web/lib/sanity/types';
import { tailwindConfig } from '@canify/theme';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

export type ProcessProps = {
	title: string;
	description: string;
	link?: InternalOrExternalLink;
	processItems: ProcessItemListProps['processItems'];
};

export const Process = ({ title, description, link, processItems }: ProcessProps) => {
	return (
		<Section paddingVerticalSize="small" className="pt-12 bg-sand-tint-50">
			<LayoutGrid>
				<div className="self-start col-span-5 col-start-1 mb-24 md:sticky top-40 md-lg:col-start-2 md-lg:col-span-4 md:mb-10">
					<h2 className="mb-12 text-brand-blue">{title}</h2>
					<p className="md:mb-12 text-brand-blue">{description}</p>
					{link && (
						<Button
							href={link.internalLink || link.externalLink}
							type="filled"
							iconRight={NextIcon}
							size="medium"
							className="hidden md:inline-flex"
						>
							{link.text}
						</Button>
					)}
				</div>
				<ProcessItemList
					className="max-w-md col-span-5 col-start-1 md:col-start-7 lg:col-start-8 lg:col-span-4"
					color={tailwindConfig.theme.colors['brand-blue'].DEFAULT}
					processItems={processItems}
				/>

				{link ? (
					<div className="max-w-md col-span-5 col-start-1 text-center md:col-span-3 md:hidden">
						<Button
							href={link.internalLink || link.externalLink}
							type="filled"
							iconRight={NextIcon}
							size="medium"
						>
							{link.text}
						</Button>
					</div>
				) : (
					<></>
				)}
			</LayoutGrid>
		</Section>
	);
};
