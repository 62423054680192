import Img from 'next/image';
import { useRouter } from 'next/router';

import { ImageAndTextList } from '@apps/web/components/molecules/image-and-text/ImageAndTextList';
import { RichText } from '@apps/web/components/molecules/rich-text/RichText';
import { Section } from '@apps/web/components/molecules/section/Section';
import { Video } from '@apps/web/components/molecules/video/Video';
import { getFileUrl, urlFor } from '@apps/web/lib/sanity/sanity';

import { Accordion } from '../../molecules/accordion/Accordion';
import { ColumnModule } from '../../molecules/column-module/ColumnModule';
import { TableModule } from '../../molecules/TableModule';
import { TextImageModule } from '../../molecules/text-image-module/TextImageModule';
import { Hero } from '../../organisms/hero/Hero';
import Doctors from '../../organisms/modules/Doctors';
import { LatestNews } from '../../organisms/modules/LatestNews';
import { PriceServices } from '../../organisms/modules/PriceServices';
import { Process } from '../../organisms/modules/Process';
import { Science } from '../../organisms/modules/Science';
import { Usp } from '../../organisms/modules/Usp';
import type { DocTypeMap } from '../types';

const isFrontPageType = (pageData: DocTypeMap['page'] | DocTypeMap['patientPage'] | DocTypeMap['frontpage']): pageData is DocTypeMap['frontpage'] => {
	return pageData._type === 'frontpage';
};

export type PageContentAreaProps = {
	pageData: DocTypeMap['page'] | DocTypeMap['patientPage'] | DocTypeMap['frontpage'];
};

export function PageContentArea({ pageData }: PageContentAreaProps) {
	const isFrontPage = isFrontPageType(pageData);
	const { title, content } = pageData;
	const layoutClasses = 'md:ml-8 lg:ml-32 max-w-3xl';
	const { query } = useRouter();
	const { backbutton } = query;

	return (
		<div className={isFrontPage ? '' : 'mb-page'}>
			<Hero {...pageData.hero} />
			{content?.map((c, index) => {
				if (c._type === 'richText') {
					return (
						<Section key={index} paddingVerticalSize="none" className="my-12 md:my-20">
							<div className={`${layoutClasses} mb-20 text-brand-blue`}>
								<RichText blocks={c.richText} />
							</div>
						</Section>
					);
				}

				if (c._type === 'doctorsSection' && c.doctors) {
					return (
						<Doctors
							doctors={c.doctors}
							title={c.title}
							link={c.link}
						/>
					);
				}

				if (c._type === 'processSection') {
					return (
						<Process
							title={c.title}
							link={c.link}
							description={c.description}
							processItems={c.processes}
						/>
					);
				}

				if (c._type === 'missionSection') {
					return (
						<Usp
							title={c.title}
							description={c.description}
							link={c.link}
						/>
					);
				}

				if (c._type === 'pricingSection') {
					return (
						<PriceServices
							title={c.title}
							desription={c.description}
							link={c.link}
							serviceList={c.services}
						/>
					);
				}

				if (c._type === 'scienceSection' && c.items?.length) {
					return (
						<Science
							title={c.title}
							imageAndTextItems={c.items}
						/>
					);
				}

				if (c._type === 'latestNewsSection' && c.items?.length) {
					return (
						<LatestNews
							newsThumbnails={c.items.map((item) => ({
								slug: item.slug,
								...item.thumbnail,
							}))}
							title={c.title}
						/>
					);
				}

				if (c._type === 'textImageAlternatingSides' && c.textImageAlternatingSides) {
					return (
						<Section key={index} className="py-40 bg-sand-tint-50 layout-padding-negation">
							<ImageAndTextList items={c.textImageAlternatingSides} />
						</Section>
					);
				}

				if (c._type === 'image') {
					const { altText, asset, caption } = c;
					const imageUrl = asset && urlFor(asset).url();

					return (
						<Section paddingVerticalSize="none" className="my-12" key={index}>
							{imageUrl && (
								<Img
									src={imageUrl}
									width={1344}
									height={894}
									alt={altText}
									objectFit="cover"
									layout="responsive"
								/>
							)}
							{caption && <p className="max-w-4xl mt-4 lg:ml-0 text-sand-shade-50">{caption}</p>}
						</Section>
					);
				}

				if (c._type === 'video') {
					const { asset, caption } = c;
					const url = getFileUrl(asset);

					return (
						<Section key={index} className="py-8 bg-brand-blue" paddingVerticalSize="none">
							<Video src={url} />
							{caption && <p className="max-w-4xl mt-4 lg:ml-0 text-blue-tint-25">{caption}</p>}
						</Section>
					);
				}

				if (c._type === 'accordionModule') {
					const { accordionItems, title, description, bgColor, referencesTitle, references } = c;

						return (
							<Accordion
								className="md:max-w-3xl col-span-12 col-start-1 lg:col-start-2"
								items={accordionItems}
								title={title}
								description={description}
								bgColor={bgColor}
								referencesTitle={referencesTitle}
								references={references}
							/>
					);
				}

				if (c._type === 'columnModule') {
					const { columnItems, title, description, bgColor } = c;

						return (
							<ColumnModule
								items={columnItems}
								title={title}
								description={description}
								bgColor={bgColor}
							/>
					);
				}

				if (c._type === 'textImageModule') {
					const { title, description, image, imagePosition, link, bgColor } = c;

						return (
							<TextImageModule
								title={title}
								description={description}
								image={image}
								imagePosition={imagePosition}
								link={link}
								bgColor={bgColor}
							/>
					);
				}

				if (c._type === 'tableModule') {
					const { table, firstColumnAsHeader, tableContainerWidthInColumns, tableContainerWidthSetting } = c;

						return (
							<TableModule
								table={table}
								firstColumnAsHeader={firstColumnAsHeader}
								tableContainerWidthInColumns={tableContainerWidthInColumns}
								tableContainerWidthSetting={tableContainerWidthSetting }
							/>
					);
				}
			})}
		</div>
	);
}
