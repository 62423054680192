import {
	ImageAndTextList,
	ImageAndTextListProps,
} from '@apps/web/components/molecules/image-and-text/ImageAndTextList';
import { LayoutGrid } from '@apps/web/components/molecules/layout-grid/LayoutGrid';
import { Section } from '@apps/web/components/molecules/section/Section';

export type ScienceProps = {
	title: string;
	imageAndTextItems: ImageAndTextListProps['items'];
};

export const Science = ({ title, imageAndTextItems }: ScienceProps) => {
	return (
		<Section className="bg-sand-tint-50" paddingVerticalSize="large">
			<h2 className="text-brand-blue mb-20 lg:mb-28">{title}</h2>
			<LayoutGrid className="-ml-8 -mr-8 md:ml-0 md:mr-0 ">
				<ImageAndTextList
					className="col-start-1 col-span-12 lg:col-start-2 lg:col-span-10"
					items={imageAndTextItems}
				/>
			</LayoutGrid>
		</Section>
	);
};
