import Img from 'next/image';

import { AltTextImageType, InternalOrExternalLink, RichTextType } from '@apps/web/lib/sanity/types';
import { NextIcon } from '@canify/ui-icon';

import { Button } from '@libs/ui/button/src/lib/button';

import { LayoutGrid } from '../layout-grid/LayoutGrid';
import { RichText } from '../rich-text/RichText';
import { Section } from '../section/Section';

type TextImageModuleProps = {
	title: string;
	description: RichTextType;
	image: AltTextImageType;
	imagePosition: 'left' | 'right';
	link: InternalOrExternalLink;
	bgColor: {
		title: string;
		value: string;
	};
};
export const TextImageModule = ({
	title,
	description,
	image,
	imagePosition,
	link,
	bgColor,
}: TextImageModuleProps) => {
	return (
		<div className="relative">
			<Section
				paddingVerticalSize="small"
				className={`py-20 my-40 lg:mx-0 lg:max-w-[80vw] ${
					bgColor.value === '#FFFFFF' ? 'bg-white text-brand-blue' : 'bg-brand-blue text-white'
				} ${imagePosition === 'left' ? 'md:ml-24 lg:ml-auto' : 'md:mr-24'}`}
			>
				<div className={'col-span-12'}>
					<LayoutGrid>
						<h2 className={`mb-8 col-span-8 ${imagePosition === 'left' ? 'md:col-start-5' : ''}`}>{title}</h2>
					</LayoutGrid>
					<div
						className={`relative md:hidden w-full aspect-[295/295] ${
							imagePosition === 'left' ? '-left-7' : '-right-7'
						}`}
					>
						<Img
							alt={image?.altText}
							src={image?.asset?.url}
							className={'object-cover w-full md:min-h-0 md:m-0'}
							layout="fill"
							objectFit="cover"
							placeholder="blur"
							blurDataURL={image?.asset?.lqip}
						/>
					</div>
					<LayoutGrid>
						<div
							className={`mt-8 max-w-3xl col-span-11 col-start-1 md:col-span-8 ${
								imagePosition === 'left' ? 'md:col-start-5' : ''
							} `}
						>
							<div>
								<RichText blocks={description} />
								<Button
									className={'pt-8'}
									iconRight={NextIcon}
									href={link?.internalLink || link?.externalLink}
									type="text"
									size="medium"
									color={bgColor.value === '#17335B' ? 'white' : 'action-red'}
								>
									{link?.text}
								</Button>
							</div>
						</div>
					</LayoutGrid>
				</div>
			</Section>
			<div
				className={`absolute hidden md:block z-10 w-full md:h-[350px] md:w-[350px] lg:h-[450px] lg:w-[450px] md:aspect-[350/350] lg:aspect-[450/450] md:top-10 lg:top-24 ${
					imagePosition === 'left' ? '-left-7 lg:left-0' : '-right-7 lg:right-0'
				}`}
			>
				<Img
					alt={image?.altText}
					src={image?.asset?.url}
					className={
						'object-cover w-full md:min-h-0 md:m-0 md:h-[350px] md:w-[350px] lg:h-[450px] lg:w-[450px]'
					}
					layout="fill"
					objectFit="cover"
					placeholder="blur"
					blurDataURL={image?.asset?.lqip}
				/>
			</div>
		</div>
	);
};
