import styles from './ProcessItem.module.scss';

export type ProcessItemType = {
	step: number;
	title: string;
	description: string;
	color: string;
};

export type ProcessItemProps = ProcessItemType & {
	className?: string;
};

export const ProcessItem = ({ className, step, title, description, color }: ProcessItemProps) => {
	return (
		<div className={`grid gap-y-4 gap-x-5 ${className}`} style={{ gridTemplateColumns: 'min-content 1fr' }}>
			<Step backgroundColor={color} step={step} />
			<h4 className={`text-brand-blue ${styles['processItem-headline-dirty-fix']}`}>{title}</h4>
			<Line className="justify-self-center" backgroundColor={color} />
			<p className="pb-12 text-brand-blue">{description}</p>
		</div>
	);
};

function Step({ step, backgroundColor }: { step: number; backgroundColor: string }) {
	return (
		<div className="relative rounded-full h-11 w-11" style={{ backgroundColor }}>
			<span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 text-white font-bold text-button-medium">
				{step}
			</span>
		</div>
	);
}

function Line({ className, backgroundColor }: { className?: string; backgroundColor: string }) {
	return <div className={`rounded-full ${className}`} style={{ backgroundColor, width: '2px' }} />;
}
