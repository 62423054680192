import Img from 'next/image';

import { LayoutGrid } from '@apps/web/components/molecules/layout-grid/LayoutGrid';
import { Section } from '@apps/web/components/molecules/section/Section';
import { Video } from '@apps/web/components/molecules/video/Video';
import { DocTypeMap } from '@apps/web/components/templates/types';
import { getFileUrl } from '@apps/web/lib/sanity/sanity';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

type HeroProps = DocTypeMap['frontpage']['hero'];

export const Hero = ({
	title,
	description,
	link,
	backgroundMedia,
	backgroundPosition,
    altText,
}: HeroProps) => {
	const backgroundSrc = backgroundMedia?.asset ? getFileUrl(backgroundMedia?.asset) : '';
	const backgroundMediaArray = backgroundSrc.split('.');
	const backgroundMediaExt = backgroundMediaArray[backgroundMediaArray.length - 1];
	// Only mp4 or image files are allowed
	const backgroundMediaIsVideo = backgroundMediaExt === 'mp4';

	return (
		<Section paddingVerticalSize="none" className="pt-40 lg:pt-60 pb-40 bg-brand-blue">
			{backgroundMedia && backgroundMediaIsVideo && <Video isBackgroundVideo src={backgroundSrc} />}
			{backgroundMedia && !backgroundMediaIsVideo && (
				<Img
					objectFit="cover"
					layout="fill"
					src={backgroundSrc}
					objectPosition={backgroundPosition || '50% 50%'}
					alt={altText}
				/>
			)}
			<div className="relative">
				<LayoutGrid className="mb-8">
					<h1 className="col-span-12 text-white max-w-md lg:max-w-xl">{title}</h1>
				</LayoutGrid>
				<LayoutGrid className="mb-8">
					<p className="body-large col-span-12 max-w-md text-white">{description}</p>
				</LayoutGrid>
				{link && (
					<Button
						size="large"
						type="filled"
						color="action-red"
						href={link?.internalLink || link?.externalLink}
						iconRight={NextIcon}
					>
						{link?.text}
					</Button>
				)}
			</div>
		</Section>
	);
};
