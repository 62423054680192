import { ReactNode } from 'react';

type FrontpageSectionProps = {
	children: ReactNode;
	paddingVerticalSize?: 'large' | 'small' | 'none';
	className?: string;
};

export const Section = ({ children, className, paddingVerticalSize = 'small' }: FrontpageSectionProps) => {
	let py = '';
	if (paddingVerticalSize === 'none') {
		py = '';
	} else if (paddingVerticalSize === 'small') {
		py = 'py-16 md:py-28';
	} else if (paddingVerticalSize === 'large') {
		py = 'py-16 md:py-34 lg:py-36';
	}

	return (
		<section className={`relative layout-padding-horizontal layout-padding-negation ${py} ${className}`}>
			<div className="max-w-[1586px] mx-auto">{children}</div>
		</section>
	);
};
