import { Trans } from '@lingui/macro';
import Img from 'next/image';
import { useState } from 'react';
import { useInViewRef } from 'rooks';

import { AltTextImageType } from '@apps/web/lib/sanity/types';
import { LocationIcon } from '@canify/ui-icon';

import s from './DoctorInfoBox.module.scss';

export type DoctorInfoBoxProps = {
	className?: string;
	image: AltTextImageType;
	name: string;
	titles?: string;
	description: string;
	city: string;
	specialistAreas: string[];
	languages: string[];
};

export const DoctorInfoBox = ({
	className,
	image,
	name,
	titles,
	description,
	city,
	specialistAreas,
	languages,
}: DoctorInfoBoxProps) => {
	const [inView, setIsVisible] = useState(false);

	const callback = (entries: IntersectionObserverEntry[]) => {
		if (entries && entries[0]) {
			setIsVisible(entries[0].isIntersecting);
		}
	};

	const [myRef] = useInViewRef(callback);

	return (
		<div ref={myRef} className={`relative ${s.wrapper} ${className}`}>
			{image?.asset && (
				<div className={'relative w-full pb-[61%] lg:pb-[72%]'}>
					<Img
						alt={image.altText}
						src={image.asset.url}
						className={'object-cover w-full md:max-w-5xl'}
						layout="fill"
						objectFit="cover"
						placeholder="blur"
						blurDataURL={image.asset.lqip}
					/>
				</div>
			)}
			<div className="relative flex flex-col mt-0 md-lg:flex-row md:-mt-14 md-lg:-mt-24">
				<div
					className={`absolute left-0 top-0 bg-brand-blue bottom-0 right-0 z-0 transition-all duration-300 ease-linear ${
						inView ? 'md:left-24 lg:left-32' : ''
					}`}
				/>
				<div
					className={[
						'bg-brand-blue p-4 md:p-8 ml-auto md:max-w-xl  relative z-10 transition-opacity duration-300 ease-linear text-white md:ml-24 lg:ml-32 delay-200',
						inView ? 'opacity-100' : 'opacity-0',
					].join(' ')}
				>
					<h3>{name}</h3>
					<p className="mb-6">{titles}</p>
					<p className="mb-4">{description}</p>
					<div className="flex">
						<LocationIcon color="white" />
						<p className="ml-2 font-bold">{city}</p>
					</div>
				</div>
				<div
					className={[
						`
						transition-opacity ease-linear duration-300 delay-200
            bg-brand-blue relative
            p-4 md:p-8 ml-auto w-full md:max-w-xl md-lg:ml-0 md-lg:max-w-xxs
            flex flex-col md:flex-row md-lg:flex-col
          `,
						inView ? 'opacity-100' : 'opacity-0',
					].join(' ')}
					style={{ minWidth: '266px ' }}
				>
					{/* background color */}
					<div className="absolute top-0 left-0 w-full h-full bg-white opacity-10" />
					<div className="mb-5 mr-24 md-lg:mr-0">
						<h4 className="mb-2 font-bold text-white body-medium">
							<Trans>Specialist areas</Trans>
						</h4>
						<ul className="ml-4">
							{specialistAreas.map((specialistArea) => (
								<li className="text-white list-disc" key={specialistArea}>
									{specialistArea}
								</li>
							))}
						</ul>
					</div>
					<div>
						<h4 className="mb-2 font-bold text-white body-medium">
							<Trans>Languages</Trans>
						</h4>
						<ul className="ml-4">
							{languages.map((language) => (
								<li className="text-white list-disc" key={language}>
									{language}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
