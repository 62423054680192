import { LayoutGrid } from '@apps/web/components/molecules/layout-grid/LayoutGrid';
import {
	NewsThumbnailList,
	NewsThumbnailListProps,
} from '@apps/web/components/molecules/news-thumbnail/NewsThumbnailList';
import { Section } from '@apps/web/components/molecules/section/Section';

type LatestNewsProps = {
	title: string;
	newsThumbnails: NewsThumbnailListProps['newsThumbnails'];
};

export const LatestNews = ({ title, newsThumbnails }: LatestNewsProps) => {
	return (
		<Section paddingVerticalSize="small">
			<h2 className="text-brand-blue mb-20 lg:mb-28">{title}</h2>
			<LayoutGrid>
				<NewsThumbnailList
					className="col-start-1 max-w-4xl md:max-w-4xl lg:max-w-none col-span-12 lg:col-start-2 lg:col-span-9"
					newsThumbnails={newsThumbnails}
				/>
			</LayoutGrid>
		</Section>
	);
};
