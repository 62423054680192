import { SanityTable } from '@apps/web/lib/sanity/types';

import { Section } from './section/Section';

type TableModuleProps = {
	table: SanityTable;
	firstColumnAsHeader: boolean;
	tableContainerWidthInColumns: '12' | '10' | '8' | '6';
	tableContainerWidthSetting: 'max' | 'min';
};
export const TableModule = ({
	table,
	firstColumnAsHeader,
	tableContainerWidthInColumns,
	tableContainerWidthSetting,
}: TableModuleProps) => {
	function getTableWidth(columns) {
		switch (columns) {
			case '12':
				return 'w-full';
			case '10':
				return 'w-10/12';
			case '8':
				return 'w-8/12';
			case '6':
				return 'w-6/12';
			default:
				return 'w-full';
		}
	}

	return (
		<Section className="py-24 lg:py-32" paddingVerticalSize="small">
			<div className="table-scroll flex justify-center items-center">
				<div className={`overflow-x-auto pb-4 ${getTableWidth(tableContainerWidthInColumns)}`}>
					<table
						className={`text-dark-blue border-collapse mx-auto ${
							tableContainerWidthSetting === 'min' ? 'w-full' : ''
						}`}
					>
						<tbody>
							{table.rows.map((row) => (
								<tr key={row._key}>
									{row.cells.map((cell, index) => (
										<td
											key={`${index}_${cell}`}
											className={`${
												firstColumnAsHeader && index === 0 ? 'bg-[#F2F4F5] table-header' : ''
											} border border-[#C2C7CD] py-3.5 px-4 table-cell`}
										>
											{cell}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</Section>
	);
};
