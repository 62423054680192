import { useEffect, useRef, useState } from 'react';

import { LayoutGrid } from '@apps/web/components/molecules/layout-grid/LayoutGrid';
import { Section } from '@apps/web/components/molecules/section/Section';
import { useBreakpoints } from '@apps/web/hooks/useBreakpoints';
import { InternalOrExternalLink } from '@apps/web/lib/sanity/types';
import { Button } from '@canify/ui-button';
import { NextIcon } from '@canify/ui-icon';

type PriceServicesProps = {
	title: string;
	desription: string;
	link?: InternalOrExternalLink;
	serviceList?: {
		title: string;
		services: string[];
	};
};

export const PriceServices = ({
	title,
	desription,
	link,
	serviceList,
}: PriceServicesProps) => {
	const serviceBox = (
		<div className="col-span-4 col-start-7 px-10 py-14 bg-brand-ochre-red">
			{serviceList && (
				<>
					<h3 className="mb-8 text-white">{serviceList.title}</h3>
					<ul className="ml-4">
						{serviceList.services.map((service) => (
							<li
								key={service}
								className="mb-2 font-bold text-white list-disc marker:text-brand-cream-yellow"
							>
								{service}
							</li>
						))}
					</ul>
				</>
			)}
		</div>
	);

	const myref = useRef<HTMLDivElement>(null);
	const { isLargeScreen } = useBreakpoints();
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const onResize = () => {
			setHeight(isLargeScreen ? myref?.current?.clientHeight || 0 : 0);
		};

		onResize();
		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myref, isLargeScreen]);

	return (
		<Section className="bg-red-shade-75" paddingVerticalSize="large">
			<div className="relative">
				<div ref={myref}>
					<LayoutGrid>
						<h2 className="max-w-sm col-span-4 col-start-1 mb-12 text-brand-ochre-red md:max-w-none md:col-start-2 lg:col-start-2 md:col-span-8 lg:col-span-3">
							{title}
						</h2>
					</LayoutGrid>
				</div>
				<LayoutGrid>
					<div className="relative z-10 col-span-4 col-start-1 md:col-start-2 md:col-span-4 lg:col-start-2 lg:col-span-3">
						<p className="max-w-lg mb-10 text-white">{desription}</p>
						{/* Service box visible on smaller screen size */}
						<div className="max-w-sm mt-16 mb-12 ml-auto md:hidden -mr-7">{serviceBox}</div>
						{link && (
							<Button
								className="whitespace-nowrap"
								size="medium"
								type="outlined"
								color="white"
								href={link.internalLink || link.externalLink}
								iconRight={NextIcon}
							>
								{link.text}
							</Button>
						)}
					</div>
					{/* Service box visible on larger screen size */}
					<div
						className={'relative top-0 left-0 hidden col-span-5 col-start-7 md:grid'}
						style={{ marginTop: `-${height}px` }}
					>
						{serviceBox}
					</div>
				</LayoutGrid>
			</div>
		</Section>
	);
};
