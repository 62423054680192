import { RichTextType } from '@apps/web/lib/sanity/types';

import { LayoutGrid } from '../layout-grid/LayoutGrid';
import { RichText } from '../rich-text/RichText';
import { Section } from '../section/Section';
import { Column, ColumnProps } from './Column';

type ColumnModuleProps = {
	title?: string;
	description?: RichTextType;
	items: ColumnProps[];
	bgColor: {
		title: string;
		value: string;
	};
};
export const ColumnModule = ({ title, description, items, bgColor }: ColumnModuleProps) => {
	function getBackgroundColor(bgColor: string) {
		switch (bgColor) {
			case '#FFE99F':
				return 'bg-brand-cream-yellow';
			case '#17335B':
				return 'bg-brand-blue';
				case '#FFFFFF':
					return 'bg-white';
			default:
				return 'bg-white';
		}
	}

	return (
		<Section paddingVerticalSize="small" className={`py-20 col-span-12 ${getBackgroundColor(bgColor.value)} ${
			bgColor.value === '#17335B' ? 'text-white' : 'text-brand-blue'
		}`}>

			<h2 className="mb-8">{title}</h2>
			<LayoutGrid>
				<div className="max-w-3xl col-span-11 col-start-1 lg:col-span-6">
					<div className="mb-20 rich-text-large lg:mb-24">
						<RichText blocks={description} />
					</div>
				</div>
			</LayoutGrid>

			<LayoutGrid className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
				{items?.map((item, i) => {
					return (
						<Column
							key={i}
							title={item.title}
							description={item.description}
							image={item.image}
							link={item.link}
							bgColor={bgColor.value}
						/>
					);
				})}
			</LayoutGrid>
		</Section>
	);
};
