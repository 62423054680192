import { ImageAndText, ImageAndTextProps } from '@apps/web/components/molecules/image-and-text/ImageAndText';

export type ImageAndTextListProps = {
	className?: string;
	items: Omit<ImageAndTextProps, 'className' | 'imagePosition'>[];
};

export const ImageAndTextList = ({ className, items }: ImageAndTextListProps) => {
	return (
		<div className={`${className}`}>
			{items.map((item, index) => (
				<ImageAndText
					key={item.title}
					className="mb-10 md:mb-20"
					{...item}
					imagePosition={index % 2 ? 'left' : 'right'}
				/>
			))}
		</div>
	);
};
